import React, { ReactNode } from 'react'
import parse from 'html-react-parser'

import Button, { ButtonProps } from '@/components/common/Button'

import {
  Content,
  Description,
  Icon,
  Inner,
  Subtitle,
  Title,
  Wrapper
} from './BlueBox.styled'

interface Props {
  title?: string
  subtitle?: string
  description?: string
  buttonText?: string
  buttonId?: string
  buttonLink?: ButtonProps['link']
  children?: ReactNode
  customButton?: ReactNode
  descriptionPadding?: boolean
  buttonDisabled?: boolean
}

function BlueBox(props: Props) {
  const {
    title,
    subtitle,
    description,
    buttonText,
    buttonId,
    buttonLink,
    children,
    descriptionPadding = false,
    buttonDisabled = false,
    customButton
  } = props

  return (
    <Wrapper>
      <Inner>
        <Content>
          <Icon>{children}</Icon>
          {title && <Title>{parse(title)}</Title>}
          {subtitle && <Subtitle strong>{parse(subtitle)}</Subtitle>}
          {description && (
            <Description descriptionPadding={descriptionPadding}>
              {parse(description)}
            </Description>
          )}
        </Content>
        {customButton
          ? customButton
          : buttonText &&
            buttonLink && (
              <Button
                text={buttonText}
                link={buttonLink}
                dataTestId={buttonId}
                {...(buttonDisabled && { style: 'disabled' })}
              />
            )}
      </Inner>
    </Wrapper>
  )
}

export default BlueBox
