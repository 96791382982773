import { useTranslation } from 'next-i18next'
import { css } from '@emotion/react'

import { Grid } from '@/styles/grid'
import BlueBox from '@/components/common/BlueBox'

import parkingUrl from '@/assets/parking-2.svg'

import Button from '../common/Button'

import { Column, Container } from './HaveParking.styled'

function HaveParking() {
  const { t } = useTranslation('common')

  const button = (
    <Button
      dataTestId={'home-haveParking-button'}
      text={t('haveParking.box.button')}
      link={'https://naviparking.com/'}
      targetBlank
    />
  )

  return (
    <Container data-test-id={'home-haveParking'}>
      <Grid>
        <Column>
          <BlueBox
            title={t('haveParking.box.title')}
            subtitle={t('haveParking.box.subtitle')}
            description={t('haveParking.box.description')}
            customButton={button}
            descriptionPadding
          >
            <img src={parkingUrl} css={imageStyle} />
          </BlueBox>
        </Column>
      </Grid>
    </Container>
  )
}

const imageStyle = css`
  width: 86px;
  height: 86px;
`

export default HaveParking
