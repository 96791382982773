import { useTranslation } from 'next-i18next'
import { useRouter } from 'next/router'

import { css } from '@emotion/react'

import { Parking } from '@/service'
import Button from '@/components/common/Button'

import { mediaUp } from '@/styles/media'

interface Props {
  parking: Parking
}

function BookParkingButtons(props: Props) {
  const { t } = useTranslation('common')
  const { parking } = props
  const router = useRouter()

  const comingSoon =
    !parking.subscriptionAvailable &&
    !parking.reservationAvailable &&
    parking.additionalInfo === 'COMING_SOON'
  const noSpots =
    parking.subscriptionAvailable &&
    parking.reservationAvailable &&
    parking.additionalInfo === 'NO_SPOTS'
  const bookReservation =
    parking.reservationAvailable && parking.additionalInfo !== 'NO_SPOTS'
  const bookSubscription =
    parking.subscriptionAvailable && parking.additionalInfo !== 'NO_SPOTS'
  const unavailable = parking.additionalInfo === 'UNAVAILABLE'
  const parkWithNavipay =
    !parking.subscriptionAvailable &&
    !parking.reservationAvailable &&
    parking.additionalInfo !== 'COMING_SOON' &&
    parking.additionalInfo !== 'UNAVAILABLE'

  const handleClick = (hash: string) => {
    router.push({
      pathname: '/parkings/[city]/[slug]',
      query: {
        city: parking.cityUrlSlug,
        slug: parking.urlSlug
      },
      hash: `${hash}`
    })
  }

  return (
    <>
      {bookReservation && (
        <Button
          css={button}
          text={t('buttonReservation')}
          onClick={() => handleClick('reservation-section')}
        />
      )}
      {bookSubscription && (
        <Button
          css={button}
          text={t('buttonSubscription')}
          onClick={() => handleClick('subscription-section')}
        />
      )}
      {noSpots && (
        <Button
          css={button}
          style={'disabled'}
          text={t('buttonNoSpots')}
          onClick={() => handleClick('no-spots-section')}
        />
      )}
      {comingSoon && (
        <Button css={button} disabled text={t('buttonComingSoon')} />
      )}
      {parkWithNavipay && (
        <Button
          css={button}
          text={t('buttonNavipay')}
          onClick={() => handleClick('navipay-section')}
        />
      )}
      {unavailable && (
        <Button css={button} text={t('buttonUnavailable')} disabled />
      )}
    </>
  )
}

const button = css`
  margin-inline-end: 5px;

  ${mediaUp('md')} {
    margin-inline-end: 10px;
  }

  ${mediaUp('lg')} {
    margin-inline-end: 20px;
  }

  &:last-of-type {
    margin-inline-end: 0;
  }
`

export default BookParkingButtons
