import { breakpoints } from '@/styles/media'

function scrollToAnchor(anchor: string) {
  const target = `${anchor}`
  const element = document.getElementById(target)
  const offset = window.innerWidth < breakpoints.lg ? 90 : 180

  element &&
    window.scrollTo({
      behavior: 'smooth',
      top:
        element.getBoundingClientRect().top -
        document.body.getBoundingClientRect().top -
        offset
    })
}

export default scrollToAnchor
