import styled from '@emotion/styled'

import { mediaColumns } from '@/styles/grid'
import { spacingSection } from '@/styles/mixins'

export const Container = styled.section`
  ${spacingSection};
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
`

export const Column = styled.div`
  ${mediaColumns({ xs: [10, 1] })};
`
