import { SVGProps } from 'react'

import { colors } from '@/styles/theme'

function ChevronDown(props: SVGProps<SVGSVGElement>) {
  const {
    fill = 'none',
    height = '32',
    width = '32',
    stroke = colors.black,
    strokeWidth = '4',
    ...rest
  } = props

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 32 32"
      fill={fill}
      height={height}
      width={width}
      stroke={stroke}
      strokeWidth={strokeWidth}
      {...rest}
    >
      <path d="m8 12 8 8 8-8" strokeLinecap="round" />
    </svg>
  )
}

export default ChevronDown
