import { css } from '@emotion/react'
import { rgba } from 'polished'

import scrollToAnchor from '@/utils/scrollToAnchor'
import ChevronDown from '@/components/icons/ChevronDown'

import { colors } from '@/styles/theme'
import { mediaUp } from '@/styles/media'

interface ScrollArrowProps {
  anchor: string
  light?: boolean
}

const ScrollArrow: React.FC<ScrollArrowProps> = props => {
  const { anchor, light = false } = props

  return (
    <button
      css={[styles.button, light && styles.light]}
      onClick={() => scrollToAnchor(anchor)}
    >
      <ChevronDown
        height={18}
        width={18}
        stroke={colors.white}
        strokeWidth={2}
      />
    </button>
  )
}

const styles = {
  button: css`
    cursor: pointer;
    position: absolute;
    bottom: 18px;
    left: 50%;
    transform: translate(-50%, 0);
    width: 24px;
    height: 24px;
    border: 1px solid ${colors.white};
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: ${rgba(colors.twilightBlue, 0.25)};
    box-shadow: 0 4px 24px 10px ${rgba(colors.twilightBlue, 0.35)};
    z-index: 5;

    ${mediaUp('md')} {
      display: none;
    }
  `,
  light: css`
    background: ${rgba(colors.twilightBlue, 0.1)};
    box-shadow: 0 4px 24px 5px ${rgba(colors.twilightBlue, 0.1)};
  `
}

export default ScrollArrow
