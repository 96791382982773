import { ReactNode } from 'react'
import { css } from '@emotion/react'

import Footer from '@/components/Footer'
import Navbar from '@/components/Navbar'
import ParkingPricingModal from '@/components/parkings/ParkingPricingModal'
import ButtonToTop from '@/components/common/ButtonToTop'

import Spinner from '../common/SpinnerPage'

const wrapper = css`
  flex: 1 0 auto;
  overflow: hidden;
`

interface Props {
  children: ReactNode
  isLoading?: boolean
  fullPage?: boolean
  isFooter?: boolean
  isButtonTop?: boolean
}

function DefaultLayout(props: Props) {
  const {
    children,
    isLoading = false,
    fullPage = false,
    isFooter = true,
    isButtonTop = true
  } = props

  return !isLoading ? (
    <>
      <Navbar />
      <main css={wrapper}>{children}</main>
      {isFooter && <Footer />}
      {isButtonTop && <ButtonToTop />}
      <ParkingPricingModal />
    </>
  ) : (
    <Spinner fullPage={fullPage} />
  )
}

export default DefaultLayout
