import styled from '@emotion/styled'

import { H2, Text1, Text2 } from '@/styles/typography'
import { mediaDown, mediaUp } from '@/styles/media'
import { blueBox } from '@/styles/mixins'

export const Wrapper = styled.div`
  ${blueBox};
  height: 100%;
  padding: 28px 20px;
  justify-content: space-between;

  ${mediaDown('md')} {
    margin-bottom: 18px;

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  ${mediaUp('lg')} {
    padding: 60px 40px;
  }
`

export const Icon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 45px;
  height: 45px;
  margin-bottom: 18px;

  svg,
  img {
    max-width: 100%;
    max-height: 100%;
  }

  ${mediaUp('lg')} {
    margin-bottom: 40px;
    width: 90px;
    height: 90px;
  }
`

export const Title = styled(H2)`
  margin-bottom: 4px;

  ${mediaUp('lg')} {
    margin-bottom: 8px;
  }
`

export const Subtitle = styled(Text1)``

export const Description = styled(Text2)<{ descriptionPadding: boolean }>`
  margin-top: 14px;

  ${mediaUp('lg')} {
    margin-top: 30px;
    ${props => props.descriptionPadding && `padding: 0 70px;`};
  }
`

export const Content = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-bottom: 18px;

  ${mediaUp('lg')} {
    margin-bottom: 40px;
  }
`

export const Inner = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 100%;
`
